import React, { lazy, Suspense, useEffect } from 'react';
import "./App.css";
import { useState } from "react";
const NavbarContainer = lazy(() => import('./components/Navbars/NavbarContainer'));
const IconsCard = lazy(() => import('./components/IconsCard/IconsCard'));

const App = () => {
  // console.log = () => { }
  console.error = () => { }
  console.warn = () => { }

  useEffect(async () => {
    modeHandle();
  }, [])
  const [mode, setMode] = useState(false);

  const modeHandle = () => {

    if (mode === false) {
      return setMode(true);
    } else {
      return setMode(false);
    }
  }
  const renderLoader = () => <p>Loading</p>;

  return (
    <Suspense fallback={renderLoader}>
      <div className="App">
        {/*<Navbars />*/}

        <NavbarContainer changed={modeHandle} />
        <IconsCard modeStatus={mode} />

        {/* <Footer />*/}

      </div>
    </Suspense>

  );
}

export default App;
